@import url("https://cdn.jsdelivr.net/npm/@xz/fonts@1/serve/cascadia-code.min.css");


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Cascadia Code', sans-serif;
}

body {
    min-height: 100vh;
    background-color: rgb(240, 240, 240);
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    display: flex;
    max-width: 1440px;
    overflow: hidden;
    min-width: 350px;
}

.textarea {
    border: 3px solid white;
    border-top: none;
    color: white;
    background-color: rgb(30, 30, 30);
    font-family: 'Cascadia Code', sans-serif;
    outline: none;
    min-height: 90vh;
    width: 45vw;
    font-size: 18px;
    padding: 15px;
    resize: none;
}

.displayarea {
    background-color: rgba(255, 255, 255, 0);
    border: 3px solid black;
    border-top: none;
    font-family: sans-serif;
    letter-spacing: 1px;
    min-height: 90vh;
    width: 45vw;
    font-size: 18px;
    padding: 15px;
    overflow-x: scroll;
    overflow-wrap: break-word;
}

.titles {
    border-bottom: none;
    display: flex;
}

.title1 {
    background-color: rgb(30, 30, 30);
    border: 3px solid white;
    width: 50%;
    color: white;
    font-size: 25px;
    font-weight: bold;
}

.title2 {
    background-color: white;
    border: 3px solid black;
    width: 50%;
    font-family: sans-serif;
    font-size: 25px;
    font-weight: bold;
}

.title1,
.title2 {
    padding: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.code{
    font-size: 14px;
    padding: 10px;
    margin: 10px;
    background-color: rgb(50, 50, 50);
    color: white;
    white-space: initial;
}

.block-code{
    font-size: 14px;
    padding: 10px;
    margin: 10px;
    background-color: rgb(70, 70, 70);
    color: white;
    border-left: 8px solid orange;
    color: white;
    white-space: initial;
}


